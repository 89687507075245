import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type QuestionItemProps = {
  question: string;
  answer: string;
};

const useStyles = makeStyles({
  questionItemBox: {
    margin: `1rem auto`,
  },
  accordion: {
    padding: `.2rem .5rem`,
  },
});

const QuestionItem: React.VFC<QuestionItemProps> = ({ question, answer }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.questionItemBox}>
      <Accordion
        expanded={expanded === `${question}`}
        onChange={handleChange(question)}
        className={classes.accordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default QuestionItem;
