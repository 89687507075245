import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import QuestionsGroup from '@/components/questions/section/QuestionsGroup';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';

const Questions: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="よくあるご質問"
      descriptionData="今までのご相談の中でも、特に多かったご質問をこちらのページにまとめています。"
      OgImageData="Questions"
    />
    <FirstView pageTitle="よくあるご質問" fileName="Questions" />
    <QuestionsGroup />
    <BottomMenu />
  </Layout>
);

export default Questions;
