import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import QuestionItem from '../middle/QuestionItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionsGroupBox: {
      padding: `1rem 0`,
      marginTop: `-3rem`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 25%, ${theme.palette.background.default} 55%, ${theme.palette.background.default} 100%)`,
    },
    cardsWrapper: {
      maxWidth: `1200px`,
      margin: `2rem auto`,
      width: `90%`,
    },
  }),
);

const QuestionsGroup: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.questionsGroupBox}>
      <Grid container className={classes.cardsWrapper} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">税務に関するご相談</Typography>
          <QuestionItem
            question="Q: 税理士さんに相談って、ちょっとハードルが高いのですが…。"
            answer="A: 税理士というと硬い印象を持たれる方が多いと思います。ですが、私たちはいつでもオープン。皆さまのご相談をお待ちしております。相談料も一回につき5000円に固定しておりますので、高額な費用が発生することはありません。一人で悩まず、まずはご相談下さい。"
          />
          <QuestionItem
            question="Q: もしものために相続と税金について知りたいんだけど…。"
            answer="A: 相続にあたっては相続税の処理とその対策が必要です。また、事前に対応しようとしても贈与税が発生する場合があります。そんな時は私たちにご相談ください。一緒に最適な税金処理について考えましょう。"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">確定申告に関するご相談</Typography>
          <QuestionItem
            question="Q: 医療費の負担が大きいんだけど…。"
            answer="A: 医療費の自己負担が家族で10万円を超えた場合、確定申告によってその負担を軽減することができます。医療費控除の手続きに関するご相談もお受けしております。"
          />
          <QuestionItem
            question="Q: 住宅ローンを組んだんだけど…。"
            answer="A: マイホーム購入には、多くの特別控除がある一方で、それらが適応される条件は複雑です。購入方法やローンの組み方に応じて、最適な控除方法を探してみませんか。"
          />
          <QuestionItem
            question="Q: 最近副業を始めたんだけど…。"
            answer="A: 副業の所得の合計が20万円に満たない場合、確定申告の必要はありません。しかし、それを超える場合は確定申告の必要があります。申告には複数の方法がありますが、その人にあった申告方法がありますので、私たちと検討してみましょう。"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">事業に関するご相談</Typography>
          <QuestionItem
            question="Q: 経費と利益の正確な把握ができてないんだけど…。"
            answer="A: 明瞭な財務状況の把握には、正確な会計データが不可欠です。私たちは月ごとの会計データの作成をお手伝いしています。それらを整理し、経費と利益を明確化していきましょう。"
          />
          <QuestionItem
            question="Q: 自分の事業に消費税が関係しそうなんだけど…。"
            answer="A: 売上が一千万円を超えると、消費税の納税義務が発生します。それには届出書や申告書の作成が必要となります。私たちは専門家として、それらの作成と税務処理をお手伝いします。"
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default QuestionsGroup;
